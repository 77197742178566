import React from 'react'
import gfm from 'remark-gfm'
import { Grid, styled, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { Home, PersonSearch } from '@mui/icons-material'

import { SubContainer, CustomMarkdown } from '../components/common/Styled'
import { buildImageLink } from '../helpers'
import SEO from '../components/common/seo'
import { routePaths } from '../constants/routes'
import Header from '../components/common/Header'

const Iframe = styled('iframe')`
  border: none;
  width: 70%;
  margin: 15px 0;
  @media (max-width: 960px) {
    width: 100%;
  }
`

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    whoIAm: strapiWhoIAm {
      description {
        data {
          description
        }
      }
      videoLink
      referencing {
        metaTitle
        metaImage {
          strapi_id
          url
          alternativeText
        }
      }
    }
  }
`

const HowWeAre = () => {
  const { whoIAm, home } = useStaticQuery(query)

  const title = whoIAm.referencing.metaTitle

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <PersonSearch />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <SEO title={title} image={whoIAm.referencing.metaImage.url} />
      <Header {...{ items, title }} />
      <Grid container justifyContent={'center'}>
        <Iframe width="420" height="315" src={whoIAm.videoLink} />
        <Typography
          maxWidth={800}
          width="100%"
          textAlign="center"
          variant="h6"
          component="div"
        >
          <CustomMarkdown
            remarkPlugins={[gfm]}
            transformImageUri={buildImageLink}
          >
            {whoIAm.description.data.description}
          </CustomMarkdown>
        </Typography>
      </Grid>
    </SubContainer>
  )
}

export default HowWeAre
